import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },


  //! ENGLISH /////////////////////////////////////////////////
  {
    path: '/english',
    name: 'english',
    component: () => import(/* webpackChunkName: "english" */ '../views/english/MenuView.vue')
  },
  {
    path: '/english/introduction',
    name: 'en introduction',
    meta: { 
      title: 'Introduction',
      pageNumber: "01",
      url: ''
    },
    component: () => import(/* webpackChunkName: "introduction" */ '../views/english/Introduction.vue')
  },
  {
    path: '/english/system',
    name: 'en system',
    meta: { 
      title: 'System',
      pageNumber: "02",
      url: ''
    },
    component: () => import(/* webpackChunkName: "system" */ '../views/english/System.vue')
  },
  {
    path: '/english/presentation',
    name: 'en presentation',
    meta: { 
      title: 'Presentation',
      pageNumber: "03",
      url: 'https://docs.google.com/presentation/d/e/2PACX-1vT7WGIlM7OPV6tAPenb4p4Y8m0btb7bjWMsJJin8RdA7uUr_Z6JOMs7IkeEeePiQA/embed?start=false&loop=false&delayms=3000'
    },
    component: () => import(/* webpackChunkName: "presentation" */ '../views/english/Presentation.vue')
  },
  {
    path: '/english/concept',
    name: 'en concept',
    meta: { 
      title: 'Expanded Concept Presentation',
      pageNumber: "04",
      url: 'https://docs.google.com/document/d/1C6RbDXYvPI_Qhd47hLPIED0E6G4bwdmO/edit?usp=sharing&ouid=103749510732115242372&rtpof=true&sd=true'
    },
    component: () => import(/* webpackChunkName: "concept" */ '../views/english/Concept.vue')
  },


  //! NORWEGIAN  /////////////////////////////////////////////////
  {
    path: '/norwegian',
    name: 'norwegian',
    component: () => import(/* webpackChunkName: "norwegian" */ '../views/norwegian/MenuView.vue'),
  },
  {
    path: '/norwegian/introduction',
    name: 'no introduction',
    meta: { 
      title: 'Introduksjon',
      pageNumber: "01",
      url: ''
    },
    component: () => import(/* webpackChunkName: "introduction" */ '../views/norwegian/Introduction.vue')
  }, 
  {
    path: '/norwegian/system',
    name: 'no system',
    meta: { 
      title: 'Systemet',
      pageNumber: "02",
      url: ''
    },
    component: () => import(/* webpackChunkName: "system" */ '../views/norwegian/System.vue')
  },
  {
    path: '/norwegian/presentation',
    name: 'no presentation',
    meta: { 
      title: 'Presentasjon',
      pageNumber: "03",
      url: 'https://docs.google.com/presentation/d/e/2PACX-1vR2BOasxx1gBm9QNRRmGV_GOEvlePYFbQ9lkgmpGqDR93QiQmH1qf7Fhy9YzjIwsg/embed?start=false&loop=false&delayms=3000'
    },
    component: () => import(/* webpackChunkName: "presentation" */ '../views/norwegian/Presentation.vue')
  },
  {
    path: '/norwegian/concept',
    name: 'no concept',
    meta: { 
      title: 'Utvidet konsept-presentasjon',
      pageNumber: "04",
      url: 'https://docs.google.com/document/d/1C6RbDXYvPI_Qhd47hLPIED0E6G4bwdmO/edit?usp=sharing&ouid=103749510732115242372&rtpof=true&sd=true'
    },
    component: () => import(/* webpackChunkName: "concept" */ '../views/norwegian/Concept.vue')
  },


  //! SWEDISH  /////////////////////////////////////////////////
  {
    path: '/swedish',
    name: 'swedish',
    component: () => import(/* webpackChunkName: "swedish" */ '../views/swedish/MenuView.vue')
  },
  {
    path: '/swedish/introduction',
    name: 'se introduction',
    meta: { 
      title: 'Introduktion',
      pageNumber: "01",
      url: ''
    },
    component: () => import(/* webpackChunkName: "introduction" */ '../views/swedish/Introduction.vue')
  },
  {
    path: '/swedish/system',
    name: 'se system',
    meta: { 
      title: 'Systemet',
      pageNumber: "02",
      url: ''
    },
    component: () => import(/* webpackChunkName: "system" */ '../views/swedish/System.vue')
  },
  {
    path: '/swedish/presentation',
    name: 'se presentation',
    meta: { 
      title: 'PowerPoint Presentation',
      pageNumber: "03",
      url: 'https://docs.google.com/presentation/d/e/2PACX-1vQ5QDn3y5NXOThFCLI0mX4EWUER7tNP1yURCB2HFyfZdBnsgzoGQXk2cRcmr93g0-F4LOI7-awceTRQ/embed?start=false&loop=false&delayms=3000'
    },
    component: () => import(/* webpackChunkName: "presentation" */ '../views/swedish/Presentation.vue')
  },
  {
    path: '/swedish/concept',
    name: 'se concept',
    meta: { 
      title: 'Utökad konceptpresentation',
      pageNumber: "04",
      url: 'https://docs.google.com/document/d/1C6RbDXYvPI_Qhd47hLPIED0E6G4bwdmO/edit?usp=sharing&ouid=103749510732115242372&rtpof=true&sd=true'
    },
    component: () => import(/* webpackChunkName: "concept" */ '../views/swedish/Concept.vue')
  },
  

  //! PORTUGUESE /////////////////////////////////////////////////
  {
    path: '/portuguese',
    name: 'portuguese',
    component: () => import(/* webpackChunkName: "aboportugueseut" */ '../views/portuguese/MenuView.vue')
  },
  
  {
    path: '/portuguese/introduction',
    name: 'pt introduction',
    meta: { 
      title: 'Introdução',
      pageNumber: "01",
      url: ''
    },
    component: () => import(/* webpackChunkName: "introduction" */ '../views/portuguese/Introduction.vue')
  },
  {
    path: '/portuguese/system',
    name: 'pt system',
    meta: { 
      title: 'Sistema',
      pageNumber: "02",
      url: ''
    },
    component: () => import(/* webpackChunkName: "system" */ '../views/portuguese/System.vue')
  },
  {
    path: '/portuguese/presentation',
    name: 'pt presentation',
    meta: { 
      title: 'Apresentação de PowerPoint',
      pageNumber: "03",
      url: 'https://docs.google.com/presentation/d/e/2PACX-1vR97IvoBnOoQPIhIzTaKJmU7WyDZVgIoSdSK59MWnnFKy4J0XQQXLqKjln-hAwRFg/embed?start=false&loop=false&delayms=3000'
    },
    component: () => import(/* webpackChunkName: "presentation" */ '../views/portuguese/Presentation.vue')
  },
  {
    path: '/portuguese/concept',
    name: 'pt concept',
    meta: { 
      title: 'Apresentação de Conceito Expandido',
      pageNumber: "04",
      url: 'https://docs.google.com/document/d/1C6RbDXYvPI_Qhd47hLPIED0E6G4bwdmO/edit?usp=sharing&ouid=103749510732115242372&rtpof=true&sd=true'
    },
    component: () => import(/* webpackChunkName: "concept" */ '../views/portuguese/Concept.vue')
  },

  //! ESTONIAN /////////////////////////////////////////////////
  {
    path: '/estonian',
    name: 'estonian',
    component: () => import(/* webpackChunkName: "aboportugueseut" */ '../views/estonian/MenuView.vue')
  },
  
  {
    path: '/estonian/introduction',
    name: 'ee introduction',
    meta: { 
      title: 'Sissejuhatus',
      pageNumber: "01",
      url: ''
    },
    component: () => import(/* webpackChunkName: "introduction" */ '../views/estonian/Introduction.vue')
  },
  {
    path: '/estonian/system',
    name: 'ee system',
    meta: { 
      title: 'Süsteem',
      pageNumber: "02",
      url: ''
    },
    component: () => import(/* webpackChunkName: "system" */ '../views/estonian/System.vue')
  },
  {
    path: '/estonian/presentation',
    name: 'ee presentation',
    meta: { 
      title: 'Esitlus',
      pageNumber: "03",
      url: 'https://docs.google.com/presentation/d/e/2PACX-1vQA-6PHAK9PoXP1zWIll1KbubSQoeTWrcWWzWJnRnx1V7v7JvWtXs3hF5BBcBQ2VA/embed?start=false&loop=false&delayms=3000'
    },
    component: () => import(/* webpackChunkName: "presentation" */ '../views/estonian/Presentation.vue')
  },
  {
    path: '/estonian/concept',
    name: 'ee concept',
    meta: { 
      title: 'Laiendatud kontseptsiooni esitlus',
      pageNumber: "04",
      url: 'https://docs.google.com/document/d/1C6RbDXYvPI_Qhd47hLPIED0E6G4bwdmO/edit?usp=sharing&ouid=103749510732115242372&rtpof=true&sd=true'
    },
    component: () => import(/* webpackChunkName: "concept" */ '../views/estonian/Concept.vue')
  },

  //! spanish /////////////////////////////////////////////////
  {
    path: '/spanish',
    name: 'spanish',
    component: () => import(/* webpackChunkName: "aboportugueseut" */ '../views/spanish/MenuView.vue')
  },
  
  {
    path: '/spanish/introduction',
    name: 'es introduction',
    meta: { 
      title: 'Introdução',
      pageNumber: "01",
      url: ''
    },
    component: () => import(/* webpackChunkName: "introduction" */ '../views/spanish/Introduction.vue')
  },
  {
    path: '/spanish/system',
    name: 'es system',
    meta: { 
      title: 'Sistema',
      pageNumber: "02",
      url: ''
    },
    component: () => import(/* webpackChunkName: "system" */ '../views/spanish/System.vue')
  },
  {
    path: '/spanish/presentation',
    name: 'es presentation',
    meta: { 
      title: 'Apresentação de PowerPoint',
      pageNumber: "03",
      url: 'https://docs.google.com/presentation/d/e/2PACX-1vT7WGIlM7OPV6tAPenb4p4Y8m0btb7bjWMsJJin8RdA7uUr_Z6JOMs7IkeEeePiQA/embed?start=false&loop=false&delayms=3000'
    },
    component: () => import(/* webpackChunkName: "presentation" */ '../views/spanish/Presentation.vue')
  },
  {
    path: '/spanish/concept',
    name: 'es concept',
    meta: { 
      title: 'Apresentação de Conceito Expandido',
      pageNumber: "04",
      url: 'https://docs.google.com/document/d/1C6RbDXYvPI_Qhd47hLPIED0E6G4bwdmO/edit?usp=sharing&ouid=103749510732115242372&rtpof=true&sd=true'
    },
    component: () => import(/* webpackChunkName: "concept" */ '../views/spanish/Concept.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
